import React from 'react'
import { Link, graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import styled from 'styled-components'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Title from '../components/Title'
const PostWrapper = styled.div`
  padding: 0 2em 3em var(--pad4);
  max-width: 40em;
`

const Frontmatter = styled.div`
  padding-top: 2em;
  padding-left: 4em;
  padding: 1em var(--pad4);
  border-bottom: solid 1px var(--offPrimeGray);
  margin-bottom: 1em;
  position: relative;
  h1 {
    margin: 0;
    color: var(--offPrimeGray);
  }
  p {
    margin: 0;
  }
  @media screen and (max-width: 30em) {
    padding-left: 4rem
  }
`

const PostsNav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0;
  padding-top: 1em;
  border-top: solid 1px var(--offPrimeGray);
`

const BackButton = styled(Link)`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  background: var(--offBg);
  left: 1px;
  top: 0;
  color: var(--offPrimeGray);
  border: solid 1px currentColor;
  border-top-color: transparent;
  border-left-width: 0;
  bottom: -1px;
  cursor: pointer;
  text-decoration: none;
  vertical-align: center;
  &:before {
    display: inline-block;
    padding-right: 0.5em;
    line-height: 1;
  }
  &:hover {
    color: var(--primeColor);
    border-color: currentColor;
    background: transparent;
  }
`

class ProjectTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        {false && <Title category="Work">{post.frontmatter.title}</Title>}
        <Frontmatter>
          <BackButton to="/work">←</BackButton>
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </Frontmatter>
        <PostWrapper>
          <MDXRenderer>{post.code.body}</MDXRenderer>

          <PostsNav style={{}}>
            <li>
              {previous && (
                <Link to={previous.fields.postPath} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.postPath} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </PostsNav>
        </PostWrapper>
      </Layout>
    )
  }
}

export default ProjectTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 120)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      code {
        body
      }
    }
  }
`
